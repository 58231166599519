<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      class="mt-10"
      v-if="!loading_table"
    ></v-skeleton-loader>
    <v-alert class="text-center" v-if="alert" type="success" dense>
      <small> تم ارسال الطلب بنجاح ,وسيتم تنفيذ الطلب خلال 4 ايام</small>
    </v-alert>

    <v-card v-if="loading_table" class="mt-10" style="margin-bottom: 100px">
      <v-card-title>
        <h4> محفظتي</h4>
        <v-spacer></v-spacer>
        <v-text-field
          label="بحث"
          append-icon="search"
          v-model="search"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn @click="dialog = true" small color="primary">ارسال طلب</v-btn>
      </v-card-title>

      <v-data-table
        class="text-center"
        :headers="request_headers"
        :items="requests"
        :search="search"
        :footer-props="$store.state.footer_props"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          USDT
          {{ Intl.NumberFormat("en-US").format(item.amount.toFixed(1)) }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span class="font-weight-bold primary--text" v-if="item.type == 0">
            <small> اضافة رصيد</small>
          </span>
          <span class="font-weight-bold success--text" v-else>
            <small> تحويل مبلغ</small>
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status == 0" color="warning" x-small>
            جاري العمل ...
          </v-chip>
          <v-chip v-else-if="item.status == 1" color="success" x-small>
            {{ item.type == 0 ? "تم الأضافة" : "تم التحويل" }}
          </v-chip>
          <v-chip v-else color="error lighten-3" x-small> تم الألغاء </v-chip>
        </template>

        <template v-slot:[`item.method`]="{ item }">
          {{ item.method ? item.method : "__" }}
        </template>
        <template v-slot:[`item.account_number`]="{ item }">
          {{ item.account_number ? item.account_number : "__" }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>اضافة طلب</v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pt-3">
          <v-alert v-if="alert_err" class="text-center" dense type="error">
            لايوجد رصيد كافى
          </v-alert>
          <v-form ref="form">
            <v-row class="align-center">
              <v-col cols="12" sm="6">
                <v-text-field
                  @input="amount_changed($event)"
                  v-model="request.amount"
                  :rules="rules.amount"
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    المبلغ
                  </template>
                </v-text-field>
                <!--/ amount -->
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  hide-details
                  class="mb-4 mt-4"
                  filled
                  label="تفنيط المبلغ"
                  disabled
                  v-model="input_amount"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  item-text="text"
                  item-value="value"
                  v-model="request.type"
                  :rules="rules.require"
                  :items="type"
                  @input="change_type($event)"
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    نوع المعاملة
                  </template>
                </v-select>
                <!--/ type -->
              </v-col>

              <v-col v-if="request.type != '0'" cols="12" sm="6">
                <v-text-field v-model="request.method" :rules="rules.require">
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    نوع الحساب
                  </template>
                </v-text-field>
                <!--/ mehtod -->
              </v-col>
              <v-col cols="12" v-if="request.type != '0'">
                <v-text-field
                  v-model="request.account_number"
                  :rules="rules.require"
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    رقم الحساب
                  </template>
                </v-text-field>
                <!--/ mehtod -->
              </v-col>

              <v-col cols="12" v-if="request.type == '0'">
                <v-text-field
                  dense
                  hide-details
                  filled
                  label="نوع المحفظة"
                  readonly
                  :value="wallet.wallet_type"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="request.type == '0'" cols="12">
                <v-text-field
                  dense
                  hide-details
                  filled
                  label="رقم المحفظة"
                  readonly
                  :value="wallet.wallet_number"
                >
                  <template slot="append-outer">
                    <v-btn @click="copy_content()" icon>
                      <v-icon v-if="!copy">content_copy</v-icon>
                      <v-icon v-if="copy" color="success">check_circle</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!--/ card text -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            :loading="save_loading"
            :disabled="save_loading"
            color="primary"
            small
            @click="send()"
            > تأكيد الدفع</v-btn
          >
          <v-btn color="error" text small @click="close()">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "requests",
  data() {
    return {
      copy: false,
      alert_err: false,
      rules: {
        require: [(v) => !!v || "الحقل مطلوب"],
        amount: [
          (v) => !!v || "الحقل مطلوب",
          (v) => !isNaN(v) || "يجب ان يكون ارقام",
        ],
      },
      input_amount: 0,
      save_loading: false,
      dialog: false,
      requests: [],
      search: "",
      alert: false,
      loading_table: false,
      type: [
        {
          text: "ايداع",
          value: "0",
        },
        {
          text: "سحب",
          value: "1",
        },
      ],
      request: {
        amount: null,
        type: null,
        method: null,
        account_number: null,
      },
      request_headers: [
        {
          value: "created_at",
          text: "تاريخ الطلب",
          align: "center",
        },
        {
          value: "amount",
          text: "المبلغ",
          align: "center",
        },
        {
          value: "type",
          text: "نوع المعاملة",
          align: "center",
        },
        {
          value: "status",
          text: "حالة الطلب",
          align: "center",
        },
        {
          value: "method",
          text: "طريقة الدفع",
          align: "center",
        },
        {
          value: "account_number",
          text: "رقم الحساب",
          align: "center",
        },
      ],
      wallet: {
        wallet_number: "",
        wallet_type: "",
      },
    };
  },
  watch: {
    dialog(newVal, oldVal) {
      if (!newVal && !oldVal) {
        this.close();
      }
    },
  },

  methods: {
    get_requests() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "requests",
            method: "get",
          },
        })
        .then((res) => {
          this.requests = res.data.data;
          this.loading_table = true;
        });
    },
    send() {
      if (this.$refs.form.validate()) {
        if (this.request.type == 0) {
          this.send_request();
        } else {
          if (parseInt(this.request.amount) <= this.$store.state.balance) {
            this.send_request();
            this.$store.state.balance =
              this.$store.state.balance - this.request.amount;
          } else {
            this.alert_err = true;
          }
        }
      }
    },
    send_request() {
      this.save_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: "send_request",
            method: "post",
          },
          data: this.request,
        })
        .then((res) => {
          this.alert = true;
          this.save_loading = false;
          this.get_requests();
          this.close();
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    close() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      this.alert_err = false;
      this.request = {
        amount: null,
        type: null,
        method: null,
        account_number: null,
      };
    },
    amount_changed(ev) {
      let val = parseInt(ev);
      // this.amount = val;
      val
        ? (this.input_amount = Intl.NumberFormat("en-US").format(
            val.toFixed(4)
          ))
        : (this.input_amount = 0);
    },
    change_type(ev) {
      if (ev == "0") {
        this.request.method = null;
        this.request.account_number = null;
      }
    },
    get_wallet() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "get_admin_settings",
            method: "get",
          },
        })
        .then((res) => {
          this.wallet = Object.assign({}, res.data.data);
        });
    },
    copy_content() {
      if (!this.copy) {
        this.copy = true;
        navigator.clipboard.writeText(this.wallet.wallet_number).then(() => {
          setTimeout(() => {
            this.copy = false;
          }, 2000);
        });
      }
    },
  },
  mounted() {
    this.get_requests();
    this.get_wallet();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-label--is-disabled,
  .theme--light.v-input--is-disabled input {
    color: #000;
  }
}
</style>
